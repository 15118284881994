import React from 'react';

export default function Privacy() {
    return (
        <div id="__next">
            <div className="docs">
                <div>
                    <h1>
                        <strong>Smart Text Desktop App PRIVACY POLICY</strong>
                    </h1>
                    <ul>
                        <li>Effective: September 1, 2024</li>
                    </ul>
                    <h1>
                        <strong>WELCOME TO Smart Text Desktop App!</strong>
                    </h1>
                    <p>
                        Your privacy is very important to us. Smart Text Desktop App offers features like rephrasing text, answering questions, and clipboard history management using keyboard shortcuts. We are committed to safeguarding your personal information while offering you an AI-powered tool for seamless productivity.
                    </p>
                    <p>
                        Smart Text Desktop App (the "App") provides AI-powered rephrasing, question-answering, and clipboard functionalities. This Privacy Policy outlines the types of information we collect from users of the App and related services (collectively, the "Service(s)"). This policy applies to the App operated by Smart Text Desktop Inc. ("we," "our," or "us"). Under applicable law, Smart Text Desktop Inc. is the "data controller" of personal data collected through the Service.
                    </p>
                    <h1>
                        <strong>INFORMATION WE COLLECT</strong>
                    </h1>
                    <p>
                        When you use the Services, we may collect the following types of information:
                    </p>
                    <ul>
                        <li>
                            <strong>Information You Provide:</strong> We collect information you voluntarily provide when using the App. This includes interactions such as when you register for an account or use certain features. Collected information may include, but is not limited to, username, email address, and other communications.
                        </li>
                        <li>
                            <strong>Usage Data:</strong> We collect non-personally identifiable information when you interact with the App. This includes:
                            <ul>
                                <li>Application usage patterns and analytics data.</li>
                                <li>Keyboard shortcuts used to invoke rephrasing or answering features.</li>
                                <li>Clipboard history for improved functionality and personalization.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Screenshots for Precision:</strong> When you use the keyboard shortcuts for rephrasing or answering, we may collect a screenshot as a reference to better understand the context and provide accurate results.
                        </li>
                    </ul>
                    <h1>
                        <strong>HOW WE USE YOUR INFORMATION</strong>
                    </h1>
                    <p>
                        The information we collect is used to improve the Services and enhance user experience. Specifically, we use your information to:
                    </p>
                    <ul>
                        <li>Provide and maintain the App's features.</li>
                        <li>Enhance functionality through clipboard history and screenshots for precise rephrasing and answering.</li>
                        <li>Understand user behavior to improve overall performance.</li>
                    </ul>
                    <h1>
                        <strong>DATA SECURITY</strong>
                    </h1>
                    <p>
                        We are committed to securing your personal information. While no method of transmission over the Internet or method of electronic storage is 100% secure, we use commercially acceptable means to protect your personal information.
                    </p>
                    <h1>
                        <strong>YOUR DATA RIGHTS AND CHOICES</strong>
                    </h1>
                    <p>
                        We believe that users should be treated equally no matter where they are, andso we are making the following options to control your data available to all users, regardless of their location. You canunsubscribe from certain emails by clicking the "unsubscribe" link they contain. You can opt out from certain cookie-related processing by following the instructions above in “Other Information.”Individuals in the European Economic Area, Canada, Costa Rica and some other jurisdictions outside the United States have certain legal rights to obtain confirmation of whether we hold personal data about them, to access personal data we hold about them (including, in some cases, in portableform), and to obtain its correction, update, amendment or deletion in appropriate circumstances. They may also object to our uses or disclosures of personal data, to request a restriction on its processing, or withdraw any consent, though suchactions typically will not have retroactive effect. They also will not affect our ability to continue processing data in lawful ways.
                    </p>
                    <p>
                        How can I access the personal data you have about me?
                    </p>
                    <p>    
                        If you would like to submit a data access request, you can do so by emailing us at support@pavan1.com. We will then start the process and provide you a link to accessthe personal data that Chat Anywhere has on you within 30 days.
                    </p>
                    <p>
                        How do I correct, update, amend, or delete the personal datayou have about me?
                    </p>
                    <p>
                        In addition to the functionality available through the "Settings" of the Services, in which you cancorrect, update, amend, or delete certain personal data, you can also request other modifications from us directly. Please write us at support@pavan1.com with the words “Personal Data Request” in the subject or body of your message, along with an explanation of what data subject right you are seeking to exercise. For your protection, we may take steps to verify identity before responding to your request.
                    </p>
                    <p>
                        How do I object or restrict the manner in which Image to Text (OCR) processes my personal data?
                    </p>
                    <p>
                        You have a right to ask us to stop using or limit our use of your personal data in certain circumstances—forexample, if we have no lawful basis to keep using your data, or if you think your personal data is inaccurate. Individuals in the European Economic Area have the right to opt out of all of our processing of their personal data for direct marketing purposes. To exercise this right, email us at support@pavan1.com. You may also click the "unsubscribe" link in anyof our marketing emails. The rights and options described above are subject to limitations and exceptions under applicable law. In addition to those rights, you have the right to lodge a complaint with the relevant supervisory authority. However, we encourage you to contact us first, and we will do our very best to resolve your concern.
                    </p>
                    
                    <h1>
                        <strong>
                            EU-U.S. PRIVACY SHIELD AND SWISS-U.S. PRIVACY SHIELD
                        </strong>
                    </h1>
                    <p>
                       Smart Text complies with the EU-U.S. Privacy Shield Framework and the Swiss – U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union and Switzerland to the United States, respectively. If there is any conflict between the terms in this privacy policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy Shield program, and to view our certification, please visithttps://www.privacyshield.gov/. Smart Text is potentially liable for onward transfers to third parties of personal dataof EU or Swiss individuals received pursuant to Privacy Shield. Smart Text is subject to oversight by the U.S. FTC. JAMS is the US-based alternative dispute resolution provider responsible for reviewing and resolving complaints about Chat Anywhere’s Privacy Shield compliance. We ask that you first submit any complaints to us at support@pavan1.com. If you aren't satisfied with our response, you can then contact JAMS athttps://www.jamsadr.com/eu-us-privacy-shield. In the event your concern still isn't addressed by JAMS, you may be entitled to a binding arbitrationunder Privacy Shield and its principles.
                    </p>
                    <h1>
                        <strong>CHANGES TO THIS POLICY</strong>
                    </h1>
                    <p>
                        We may update this Privacy Policy from time to time. If changes are made, we will notify you by updating the effective date at the top of this policy.
                    </p>
                    <p>
                        If you have any questions about this Privacy Policy, please contact us at support@pavan1.com
                    </p>
                </div>
            </div>
        </div>
    );
}
